import React from "react";
import {
  HomeOutlined,
  TeamOutlined,
  UserSwitchOutlined,
  UsergroupAddOutlined,
  //   ReloadOutlined,
  //   SnippetsOutlined,
  DeploymentUnitOutlined,
} from "@ant-design/icons";
import { Feature } from "../constants/feature";

export const HomeRoute = [
  {
    key: "home",
    name: "Home",
    path: "/",
    icon: HomeOutlined,
    features: [],
    exact: true,
    component: React.lazy(
      () =>
        import(
          /* webpackPreload: true,
                webpackChunkName: "home-chunk", */
          "../components/home/home.component"
        )
    ),
  },
  {
    key: "admin",
    name: "Admin",
    path: "/admin",
    icon: TeamOutlined,
    features: [Feature.ADMIN],
    nonMenuLink: true,
    subMenu: true,
    subRoute: [
      {
        key: "user",
        name: "User Management",
        path: "/admin/user",
        icon: UserSwitchOutlined,
        features: [Feature.ADMIN],
        exact: true,
        component: React.lazy(
          () =>
            import(
              /* webpackPreload: true,
                        webpackChunkName: "user-management", */
              "../components/admin/manage/user/user.management.component"
            )
        ),
      },
      {
        key: "role",
        name: "Role Management",
        path: "/admin/role",
        icon: UsergroupAddOutlined,
        features: [Feature.ADMIN],
        exact: true,
        component: React.lazy(
          () =>
            import(
              /* webpackPreload: true,
                        webpackChunkName: "role-management", */
              "../components/admin/manage/role/role.management.component"
            )
        ),
      },
    ],
  },
  {
    key: "migration",
    name: "Migration",
    path: "/migration",
    component: React.lazy(
      () =>
        import(
          /* webpackPreload: true,
                webpackChunkName: "migration-home", */
          "../components/migration/home.migration.component"
        )
    ),
    features: [Feature.MIGRATION, Feature.ADMIN],
    exact: true,
    icon: DeploymentUnitOutlined,
    subMenu: false,
    subRoute: [
      {
        name: "Migration Status",
        path: "/migration/:application_id/status",
        exact: true,
        features: [Feature.MIGRATION, Feature.ADMIN],
        component: React.lazy(
          () =>
            import(
              /* webpackPreload: true,
                        webpackChunkName: "migration-status", */
              "../components/migration/status.migration.component"
            )
        ),
      },
    ],
  },
];
