export const Feature = {
    ADMIN: "admin",
    MIGRATION: "migration",
}

export const LogColor = {
    50: "crimson-log",
    40: "darkred-log",
    30: "orange-log",
    20: "black-log",
    10: "black-log",
}