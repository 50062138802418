import { notification } from "antd";

// eslint-disable-next-line
const formatUnicorn = require("format-unicorn");

function construct_url(url: string, args: any, query_args: any = null) {
  let _url = route_url[url];
  _url = _url.formatUnicorn({ protocol: "http", host: "localhost" });
  _url = _url.formatUnicorn(args);
  if (query_args) _url = _url + "?" + new URLSearchParams(query_args);
  return _url;
}

export const DataService = {
  redirect: (
    url: string,
    url_args: any = null,
    body = null,
    headers: any = {},
    query_args: any = null
  ) => {
    url = construct_url(url, url_args, query_args);
    // return fetch(url, {
    //     method: "GET",
    //     headers: new Headers(headers), mode: "no-cors",
    //     redirect: 'follow'
    // })

    window.location.href = url;
    return null;
  },
  get: async (
    url: string,
    url_args: any = null,
    body: any = null,
    headers: any = {},
    query_args: any = null,
    failure_message = true
  ) => {
    url = construct_url(url, url_args, query_args);
    headers["Access-Control-Allow-Origin"] = "*";
    return fetch(url, {
      method: "GET",
      headers: new Headers(headers),
      mode: "cors",
    })
      .then((response) => {
        let _response = response.json();
        if (!response.ok) {
          _response.then((response) => {
            if (response.type && response.message && failure_message)
              notification.error({
                message: response.message,
              });
          });
          throw new Error("APi failure");
        }
        return _response;
      })
      .catch((error) => {
        console.log(error);
        if (!failure_message) return error;
      });
  },
  post: async (
    url: string,
    url_args: any = null,
    body: any = {},
    headers: any = {},
    query_args: any = null
  ) => {
    if (url_args) url = construct_url(url, url_args, query_args);
    headers["Access-Control-Allow-Origin"] = "*";
    headers["content-type"] = "application/json";

    return fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        credentials: "include",
      },
      mode: "cors",
      body: JSON.stringify(body),
    })
      .then((response) => {
        let _response = response.json();
        if (!response.ok) {
          _response.then((response) => {
            if (response.message)
              notification.error({
                message: response.message
              });
          });
          throw new Error();
        }
        return _response;
      })
      .catch((error) => console.log("APi failure"));
  },
  delete: async (
    url: string,
    url_args: any = null,
    body: any = {},
    headers: any = {},
    query_args: any = null
  ) => {
    if (url_args) url = construct_url(url, url_args, query_args);

    return fetch(url, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        credentials: "include",
      },
      mode: "cors",
      body: JSON.stringify(body),
    })
      .then((response) => {
        let _response = response.json();
        if (!response.ok) {
          _response.then((response) => {
            if (response.message)
              notification.error({
                message: response.message
              });
          });
          throw new Error("APi failure");
        }
        return _response;
      })
      .catch((error) => console.log(error));
  },
};

export const route_url: any = {
  auth_sso: "/orion/auth/google",
  auth_logout: "/orion/auth/logout",
  get_user_ctx: "/orion/auth/user",

  admin_get_users: "/orion/admin/users",
  admin_create_user: "/orion/admin/user",
  admin_delete_user: "/orion/admin/user/{_id}",
  admin_update_user: "/orion/admin/user/{_id}",
  admin_service_status: "/orion/server/status",

  admin_get_min_roles: "/orion/admin/roles/min",
  admin_get_roles: "/orion/admin/roles",
  admin_create_role: "/orion/admin/role",
  admin_update_role: "/orion/admin/role/{_id}",
  admin_delete_role: "/orion/admin/role/{_id}",

  kf_access_check: "/orion/kf/auth",
  kf_all_session: "/orion/kf/sessions",

  trigger_migration: "/orion/kf/migration/migrate",
  remove_migration_lock: "/orion/kf/migration/{application_id}/lock",
  migration_logs: "/orion/kf/migration/{application_id}/status/logs",
  clear_migration_logs: "/orion/kf/migration/{application_id}/status/logs",
  reset_migration:
    "/orion/kf/migration/{application_id}/{migration_type}/reset/migration/status",
  domain_change: "/orion/kf/migration/{application_id}/domainname/change",
  trigger_clone_migration: "/orion/kf/migration/clone/migrate",
  sync_users_and_group: "/orion/kf/migration/user/sync",
  trigger_integration_retest: "/orion/kf/migration/integration/retest"
};
